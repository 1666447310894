<template>
  <b-container class="text-center vehicle-details">
    <h2 class="text-primary title">Vehicle Photos</h2>
    <b-link
      class="text-primary"
      v-show="$store.getters.listingPlanID === 2"
      @click="toPage(3)"
    >
      Skip this step
    </b-link>

    <div class="my-4">
      <TheListingCard />
    </div>

    <h5 class="text-left upload-title" id="upload-photos-holder">
      {{ $t("ListingProcess.SelectAndUploadPhotos") }}
    </h5>

    <b-link v-b-modal.photoGuidelines class="my-4 guideline" v-if="!sucess">
      {{ $t("listing.label.seePhotoGuidelines") }} <b-icon-arrow-right />
    </b-link>
    <!-- upload image preview -->
    <b-alert
      variant="success"
      class="text-center mt-5 alert-box success-box"
      :show="sucess"
      dismissible
    >
      <i class="fa fa-check-circle"></i>
      <h5>{{ $t("ListingProcess.imagesUploadedSuccessfully") }}</h5></b-alert
    >
    <TheUpload />
    <!-- upload image preview -->
    <TheImagesDelete />

    <b-modal id="photoGuidelines" hide-footer centered>
      <template #modal-title>
        {{ $t("listing.label.photoGuidelines") }}
      </template>
      <ul class="m-3 p-3">
        <li>
          {{ $t("listing.label.photoGuideline1") }}
        </li>
        <li>
          {{ $t("listing.label.photoGuideline2") }}
        </li>
        <li>{{ $t("listing.label.photoGuideline3") }}</li>
        <li>{{ $t("listing.label.photoGuideline4") }}</li>
      </ul>
    </b-modal>
    <div class="d-flex justify-content-between align-items-center wizard-footer">
      <b-button pill variant="half-dark" class="px-3 cancelButton" v-b-modal.cancelModal>
        <v-icon>mdi-close</v-icon> {{ $t("global.button.cancel") }}
      </b-button>
      <div class="saveAndLeave">
        <TheSaveAndLeave />
      </div>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      images: null,
      host:
        this.$store.getters.hostURL +
        "sitemedia/Images/Listings/" +
        this.$store.getters.listingStockID +
        "/",
    };
  },
  props: {
    nextButton: { type: Function },
    toPage: { type: Function },
  },
  components: {
    TheUpload: () => import("@/components/global/TheUploadComponent"),
    TheListingCard: () => import("@/components/listing/TheListingCard"),
    TheImagesDelete: () => import("@/components/global/TheUploadImagesDelete"),
    TheSaveAndLeave: () => import("@/components/listing/TheSaveAndLeaveButton"),
  },
  methods: {
    async deleteImages(listingTagId, tagId) {
      try {
        await axios.post("Listing/SetListing", {
          ListingId: this.$store.getters.listingStockID,
          Tags: [
            {
              ListingTagId: listingTagId,
              TagId: tagId,
              Remove: true,
            },
          ],
        });
        this.$store.dispatch(
          "vehicleImageList",
          this.$store.getters.vehicleImageList.filter(
            (e) => e.ListingTagId !== listingTagId
          )
        );
      } catch (error) {
        console.log(error);
      }
    },
    checkNextButton() {
      if (this.hasUploaded) {
        this.nextButton("page3", false);
      } else {
        this.nextButton("page3", true);
      }
    },
  },
  computed: {
    hasUploaded() {
      return this.$store.getters.vehicleImagesUploaded;
    },
    sucess() {
      return (
        this.$store.getters.vehicleImageList.length > 0 &&
        this.$store.getters.vehicleImagesUploaded
      );
    },
  },
  watch: {
    hasUploaded() {
      this.checkNextButton();
    },
  },
  beforeMount() {
    this.checkNextButton();
  },
};
</script>

<style lang="scss" scoped>
.guidline {
  font-size: 20px;
}
.cancelButton {
  margin-left: 210px;
}
</style>

<style lang="scss">
.alert-box {
  border: none;
  display: flex;
  align-items: center;
  min-height: 30px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 40px !important;
  b {
    font-weight: 600;
    color: #111;
    font-family: "Inter", sans-serif;
  }
  p {
    margin-bottom: 0 !important;
    margin-top: 12px;
  }
  &.success-box {
    background: #dbf0d9;
  }
  &.info-box {
    background: #e6f0f9;
    color: #111111;
  }
  h5 {
    font-weight: 500;
    font-size: 16px;
    color: #4bb543;
    margin-bottom: 0;
  }
  i {
    margin-right: 8px;
    color: #4bb543;
    font-size: 25px;
  }
  .close {
    position: absolute;
    right: 40px;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    top: 50% !important;
    transform: translateY(-50%);
    font-size: 40px;
    font-weight: 200;
    color: #111;
    opacity: 1;
    padding: 0;
  }
}
.upload-title {
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter", sans-serif !important;
  padding-bottom: 8px;
  border-bottom: 1px solid #dbdbdb;
  margin-top: 64px;
  margin-bottom: 24px;
}
</style>
